@import '../../node_modules/@sstk/design-tokens/base/font/tokens.css';
@import '../components/BulkEditor/swiper-overrides.css';

html {
  font-size: var(--token-base-font-body-primary);
}
html,
body {
  padding: 0;
  margin: 0;
}

/* 
* Leaflet global overrides
*/

.leaflet-pane .leaflet-overlay-pane * {
  cursor: grab;
}

/* Applies white background on SVG element */
.leaflet-pane .leaflet-overlay-pane > svg {
  background-color: white;
}

/* Hides the link to Leaflet */
.leaflet-control-attribution {
  display: none;
}

